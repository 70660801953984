import Head from 'next/head'
import * as React from 'react'
import { InjectedIntlProps, injectIntl } from 'react-intl'

interface PageTitleProps {
  title?: string
  noMainTitle?: boolean
  dontTranslate?: boolean
}

const PageTitle: React.FunctionComponent<PageTitleProps & InjectedIntlProps> = ({
  title,
  noMainTitle,
  intl,
  dontTranslate,
}) => {
  const translatedMainTitle = noMainTitle ? `` : intl.formatMessage({ id: process.env.SITE_TITLE || '' })
  const separator = title && !noMainTitle ? ' | ' : ``
  const translatedTitle = title ? (dontTranslate ? title : intl.formatMessage({ id: title })) : ``

  return (
    <Head>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
      <link
        href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap"
        rel="stylesheet"
      ></link>
      <title>
        {translatedTitle}
        {separator}
        {translatedMainTitle}
      </title>
    </Head>
  )
}

export default injectIntl(PageTitle)
