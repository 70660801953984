import PortalProgram from '@classes/Program'
import { ScheduleSession } from '@interfaces/ScheduleSession'
import { ProgramStudentInfo } from '@interfaces/Student'
import { DATE_FORMAT, LESSON_STATUS, PROGRAM_TYPES } from '@utils/constants'
import { FLEX } from '@utils/constants'
import { isProgramOnline as isOnline } from '@utils/helpers/programs'
import { isFlexProgram } from '@utils/helpers/sessions'
import includes from 'lodash/includes'
import moment from 'moment'

class MyBerlitzLesson {
  lesson?: ScheduleSession
  StudentProgram?: PortalProgram
  program?: ProgramStudentInfo
  tz: string

  constructor(lesson?: ScheduleSession, StudentProgram?: PortalProgram, tz?: string) {
    this.lesson = lesson
    this.StudentProgram = StudentProgram
    this.program = StudentProgram?.program
    this.tz = tz || moment.tz.guess()
  }

  get _userId() {
    return this.lesson?.InstructorId ?? this.lesson?.CustomerId ?? ''
  }

  get _user() {
    return this.lesson?.InstructorName ?? this.lesson?.StudentName ?? ''
  }

  get _isMeeting() {
    return this.lesson?.Type === 'Meeting'
  }

  get _location() {
    return (this._isProgramOnline ? 'Online' : this.lesson?.Room) ?? ''
  }

  get EndTime() {
    return this.lesson?._lastLessonEndTime || this.lesson?.EndTime
  }

  get _isProgramOnline() {
    return isOnline(this.lesson?.Type, this.lesson?.DeliveryMode)
  }

  get _isOrientation() {
    return (this.lesson?.Type || '').toLocaleLowerCase().includes('orientation')
  }

  get ProgramId() {
    return this.lesson?.ProgramId
  }

  set OverrideProgramName(name: string) {
    const lesson = this.lesson || { ProgramName: '' }
    lesson.ProgramName = name
  }

  get Status() {
    return this.lesson?.Status
  }

  get Materials() {
    return this.lesson?.Materials
  }

  get Grade() {
    return this.lesson?.Grading
  }

  get StartTime() {
    return this.lesson?.StartTime
  }

  get IsFlexSession() {
    return (
      (this.lesson?.UnitId && this.lesson?.LPId) ||
      PROGRAM_TYPES.FLEX.some((program) => program.toLowerCase().includes((this.lesson?.Type || '').toLowerCase()))
    )
  }

  get LessonTypeWithMaxStudent() {
    const maxStudent = this.lesson?.MaxNumberofStudentsInGroup
    return this.lesson?.Type + (maxStudent ? ` (${maxStudent})` : '')
  }

  get LCSTitle() {
    return FLEX.includes(this.lesson?.Type || '') ? (this.lesson?.UnitFullName || '').split(':')[0] : ''
  }

  get InstructorId() {
    return this.lesson?.InstructorId || ''
  }

  get BoD1LessonName() {
    return this.lesson?.UnitFullName || 'Custom lesson'
  }

  getTypeName = (isStudent: boolean) =>
    !isStudent && isFlexProgram(this.lesson?.Type ?? '')
      ? 'Live coaching session'
      : this.StudentProgram?.ProgramTypeDisplay || this.LessonTypeWithMaxStudent

  getTimerange = (toUserDate, format = DATE_FORMAT.HM) =>
    `${toUserDate(this.lesson?.StartTime, DATE_FORMAT.TZ, format)} - ${toUserDate(
      this.EndTime,
      DATE_FORMAT.TZ,
      format
    )}`

  getStartEndTime = (toUserDate, format = DATE_FORMAT.HM) => [
    toUserDate(this.lesson?.StartTime, DATE_FORMAT.TZ, format),
    toUserDate(this.EndTime, DATE_FORMAT.TZ, format),
  ]

  getUserStartTime = (toUserDate, format?: string) => toUserDate(this.StartTime, DATE_FORMAT.TZ, format)

  getLessonCountTexts = (intl) =>
    this?.lesson?._lessonCount ?? 0 > 1
      ? ` ${intl.formatMessage(
          {
            id: 'lesson-count',
            defaultMessage: `({count} lessons)`,
          },
          {
            count: this?.lesson?._lessonCount,
          }
        )}`
      : ''

  getEventCountTexts = (intl) =>
    this?.lesson?._lessonCount ?? 0 > 1
      ? ` ${intl.formatMessage(
          {
            id: 'event-count',
            defaultMessage: `({count, plural, =0 {0 events} one {# event} other {# events}})`,
          },
          {
            count: this?.lesson?._lessonCount,
          }
        )}`
      : ''

  getLessonTimeRange = (getDateWithUserTimezone) => {
    let time = getDateWithUserTimezone(this.lesson?.StartTime).format(DATE_FORMAT.hM) || ''
    if (this.lesson?.EndTime) {
      time = time.concat(' - ', getDateWithUserTimezone(this.lesson?.EndTime).format(DATE_FORMAT.HMa))
    }
    return time
  }

  isPastLesson = () => moment(this.lesson?.EndTime, DATE_FORMAT.TZ).diff(moment(), 'seconds') < 0

  afterCutoffTime = (timeoverride?: string, cutOffMinutes: number = 0) =>
    this.StudentProgram?.isAfterCutOffTime(moment(timeoverride || this.lesson?.StartTime), cutOffMinutes)

  isNotifiable = (timeoverride?: string) =>
    includes(LESSON_STATUS.CAN_NOTIFY, (this.lesson?.Status || '').toLowerCase()) &&
    !this.isPastLesson() &&
    this.afterCutoffTime(timeoverride, this.StudentProgram?.program?.CancelCutOffMinutes) &&
    this.StudentProgram?._hasCancelAddOn

  isCancellable = (timeoverride?: string) => {
    if (
      (this.StudentProgram?.program?.IsGroup && !this.StudentProgram?.program?.IsLead) ||
      this.program?.PortalNotNecessary
    ) {
      return false
    }

    return (
      includes(LESSON_STATUS.CAN_CANCEL, (this.lesson?.Status || '').toLowerCase()) &&
      (!this.afterCutoffTime(timeoverride, this.StudentProgram?.program?.CancelCutOffMinutes) ||
        this.StudentProgram?.isEnrollDateAfterUserDate(timeoverride || this.lesson?.StartTime)) &&
      this.StudentProgram?._hasCancelAddOn &&
      !this._isOrientation
    )
  }

  isNotifyAbsenceAllowed = (timeoverride?: string) =>
    (this.StudentProgram?.isProgramF2F() || this.StudentProgram?.isProgramLiveOnline()) &&
    !this.StudentProgram?.isFlexProgram() &&
    !this.StudentProgram?.program?.IsGroup &&
    LESSON_STATUS.CAN_NOTIFY.includes((this.lesson?.Status || '').toLocaleLowerCase()) &&
    this.afterCutoffTime(timeoverride, this.StudentProgram?.program?.CancelCutOffMinutes)

  showRescheduleButton = (timeoverride?: string) => {
    const show =
      includes(LESSON_STATUS.CAN_RESCHEDULE, (this.lesson?.Status || '').toLowerCase()) &&
      !this.afterCutoffTime(timeoverride, this.StudentProgram?.program?.SchedulingMinutesBeforeCutoff) &&
      this.StudentProgram?._showLessonActions &&
      !this.StudentProgram?.isFlexProgram() &&
      // PTL-695: Orientation: Hide Reschedule and Cancel
      !this._isOrientation &&
      !this.program?.PortalNotNecessary
    return (this.program?.IsGroup ? show && this.program?.IsLead : show) && this.StudentProgram?.IsRescheduleAvailable
  }

  showReschedBlockModal = () =>
    (this.StudentProgram?.program?.TotalRescheduleAllowed || 0) >= 1 && this.StudentProgram?.program?.BlockScheduling
}

export default MyBerlitzLesson
