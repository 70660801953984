import { GroupClassList } from '@interfaces/LessonGroups'
import { GroupClassListItem } from '@interfaces/MySchedule'
import { BookingSession } from '@interfaces/SearchSessions'
import { DATE_FORMAT, PROGRAM_TYPES } from '@utils/constants'
import moment from 'moment'

export const OrientationTypes: string[] = ['Orientation Session']

export const groupSessions = (sessions: BookingSession[], queryTimeString: any, UnitsPerLesson = 1) => {
  const suggested: BookingSession[] = []
  const exact: BookingSession[] = []
  const before: BookingSession[] = []
  const after: BookingSession[] = []

  sessions.map((session) => {
    const currentTime = moment(session.StartDatetime, DATE_FORMAT.DT)
    const diff = currentTime.diff(queryTimeString, 'hours', true)
    session.LessonCount = session.Units / UnitsPerLesson

    if (diff === 0) {
      exact.push(session)
    } else {
      suggested.push(session)
    }
    // else if (diff <= 1 && diff > 0) {
    //   after.push(session)
    // } else if (diff >= -1 && diff < 0) {
    //   before.push(session)
    // }
  })
  return {
    suggested,
    exact,
    // before,
    // after,
  }
}

export const cleanUpSFID = (salesforceID) => {
  // 1 - Split and remove after the hash
  // 2 - If the "customerId" contains "#EXT#", replace the last occurrence of "_" with AT @
  return salesforceID && salesforceID.split('#')[0].replace(/_([^_]*)$/, '@' + '$1')
}

export const isFlexProgram = (ProgramType?: string): boolean =>
  ProgramType ? PROGRAM_TYPES.FLEX.map((flex) => flex.toLowerCase()).includes((ProgramType || '').toLowerCase()) : false

export const isBoD1 = (ProgramType?: string): boolean =>
  ProgramType
    ? PROGRAM_TYPES.ON_DEMAND_1.map((flex) => flex.toLowerCase()).includes((ProgramType || '').toLowerCase())
    : false

export const removeOrientation = (groups: GroupClassListItem[] = []): GroupClassListItem[] =>
  groups.filter(({ Type }) => !OrientationTypes.some((oType) => oType.toLowerCase() === (Type || '').toLowerCase()))

export const isOrientation = (session: GroupClassListItem | GroupClassList): boolean =>
  OrientationTypes.some((oType) => oType.toLowerCase() === (session.Type || '').toLowerCase()) &&
  session.DeliveryMode === 'Online'

export const displayLessonRegBtn = (sched: GroupClassListItem, isCutoff) => {
  const validLesson = (sched.Status !== 'Pending' && !isCutoff && !isOrientation(sched)) || isFlexProgram(sched?.Type)
  return validLesson && Boolean(sched.SessionInfoList.length)
}
